import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, useStaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const BlogRoll = ({ limit }) => {
  const data = useStaticQuery(graphql`
    query BlogRollPropertyQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            tags: { ne: "property" }
          }
        }
      ) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              date(formatString: "MMMM DD, YYYY")
              author
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 450, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              tags
            }
          }
        }
      }
    }
  `);
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="columns is-multiline">
      {posts &&
        posts.map(({ node: post }, idx) => {
          if (limit && idx >= limit) return null;
          return (
            <div
              key={post.id}
              className="is-parent column is-3"
              style={{ minHeight: "30rem" }}
            >
              <article
                className={`box ${
                  post.frontmatter.featuredpost ? "is-featured" : ""
                }`}
              >
                <header>
                  <Link to={post.fields.slug}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: post.frontmatter.title,
                      }}
                      style={{
                        width: "100%",
                        height: "20rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Link>
                </header>
                <div className="container py-4">
                  <Link to={post.fields.slug}>
                    <h6 className="title is-6 has-text-link">
                      {post.frontmatter.title}
                    </h6>
                  </Link>
                  <p className="has-text-grey-dark">
                    {post.frontmatter.author}
                    <br />
                    {post.frontmatter.date} <br />
                    {Array.isArray(post.frontmatter.tags)
                      ? post.frontmatter.tags.map((tag) => `${tag} `)
                      : null}
                  </p>
                  <p>
                    {post.excerpt}
                    <br />
                    <br />
                    <Link className="button is-link" to={post.fields.slug}>
                      Keep Reading →
                    </Link>
                  </p>
                </div>
              </article>
            </div>
          );
        })}
    </div>
  );
};

BlogRoll.propTypes = {
  limit: PropTypes.number,
};

export default BlogRoll;
